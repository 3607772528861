
import { Component, Vue, Prop } from 'vue-property-decorator'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'SkeletonSku'
})
export default class extends Vue {
  @Prop({ required: true }) private skusLoading!: boolean

  public userStore: any = useUserStore()

  get isWholesaler() {
    return this.userStore.roles.includes('wholesaler')
  }
}
