
import { Component, Prop, Vue } from 'vue-property-decorator'
import { useUserStore } from '@/stores/user'
import { DirectUpload } from 'activestorage'

@Component({
  name: 'DirectUpload'
})
export default class extends Vue {
  @Prop({ required: false, default: `${process.env.VUE_APP_API_URL}/rails/active_storage/direct_uploads` }) uploadUrl!: string
  @Prop({ required: false, default: true }) multiple!: boolean
  @Prop({ required: false, default: 'picture' }) type!: string
  @Prop({ required: false, default: () => [] }) fileList!: any
  @Prop({ required: false, default: 'Fichiers jpg/png avec une taille inférieure à 500kb' }) tip!: string
  @Prop({ required: false, default: true }) showFiles!: boolean

  private userStore: any = useUserStore()

  progress = 0
  uploads: any[] = []
  singleFileDetails: any = null

  get blobList(): any {
    return this.fileList.map((e: any) => {
      return { name: e?.filename || this.singleFileDetails?.file?.name, url: e?.url || e }
    })
  }

  upload(file: any) {
    const upload = new DirectUpload(file.file, file.action, {
      directUploadWillCreateBlobWithXHR: (xhr) => {
        xhr.setRequestHeader('Authorization', `Bearer ${this.userStore.user.access_token}`)
      }
    })
    this.uploads.push({ file, upload })
    this.progress = 0
    upload.create((error, blob) => {
      if (error) {
        // TODO
      } else {
        if (!this.multiple) this.singleFileDetails = file
        this.$emit('upload', { file, blob })
      }
    })
  }
}
