
import { Component, Prop, Vue } from 'vue-property-decorator'
import CountTo from 'vue-count-to'

@Component({
  name: 'PanelGroup',
  components: {
    CountTo
  }
})
export default class extends Vue {
  @Prop({ required: true }) private panels!: any

  private handleSetLineChartData(type: string) {
    this.$emit('handle-set-line-chart-data', type)
  }

  get panelLg() {
    if (this.panels.length === 3) return 8
    else if ((this.panels.length === 1)) return 24
    else return 6
  }
}
