
import { Component, Prop, Vue } from "vue-property-decorator"
import JsFileDownloader from 'js-file-downloader'

import { Delivery, Order } from "@/models"

@Component({
  name: 'DownloadManagerDialog'
})
export default class extends Vue {
  @Prop({ required: true }) private order!: Order
  @Prop({ required: true }) private delivery!: Delivery
  @Prop({ required: false, default: 'CSV' }) private type!: string

  private pollingInterval: any = null
  private pollingData: any = null
  private loadingDownload = false
  private pollingInProgress = false
  private generatingFile = false
  private generatePDF = false

  get fileName(): string {
    return `${this.order.number}_${this.delivery.codeFileName}`
  }

  async downloadCodeFile(): Promise<void> {
    this.loadingDownload = true
    let fetchedDelivery: any = null

    try {
      fetchedDelivery = await this.delivery.download()
      this.$emit('updateOrderDelivery', { orderId: this.order.id, deliveryId: fetchedDelivery.data.id })
    } catch (e) {
      this.$notify({
        title: this.$t('orders.notification.downloadError.title') as string,
        message: this.$t('orders.notification.downloadError.message') as string,
        type: 'error',
        duration: 2000
      });

      this.loadingDownload = false
    }

    await new JsFileDownloader({
      url: this.delivery.codesFile,
      filename: this.order.codeFileName,
      contentType: 'text/csv',
      forceDesktopMode: true
    }).catch(() => {
      this.$notify({
        title: this.$t('orders.notification.downloadError.title') as string,
        message: this.$t('orders.notification.downloadError.message') as string,
        type: 'error',
        duration: 2000
      });
    });

    this.loadingDownload = false
  }
}
