
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CardDistribution, Gift } from '@/models/index'

@Component({
  name: 'SelectCustomer',
  components: {
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private gift!: Gift
  @Prop({ required: true }) private cardDistributions!: CardDistribution[]

  get giftAmount(): number {
    return this.cardDistributions.reduce((acc: number, current: CardDistribution): number => {
      return acc + (current.amount * current.quantity)
    }, 0)
  }
}
