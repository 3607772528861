
import { Component, Vue, Prop } from 'vue-property-decorator'
import { CardDistribution, Order } from '@/models/index'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'OrderFormDetails',
  components: {
    SkeletonSku: () => import('./SkeletonSku.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private order!: Order
  @Prop({ required: true }) private status!: string
  @Prop({ required: true }) private skusLoading!: string

  public userStore: any = useUserStore()

  private cardDistributionsList: CardDistribution[] = []
  private activeLineCardDistributions = 0
  private editLane: any = null

  get freeFaceValue() {
    return this.order.giftCard.skus.find(e => e.faceValueFree) !== undefined
  }

  get faceValueAvailable() {
    return this.order.giftCard.skus.sort((a: any, b: any) => b.faceValue - a.faceValue).map(e => {
      return { label: `${e.faceValue} €`, value: e.id }
    })
  }

  get cardDistributionsAlertType() {
    return this.order.cardDistributionsSum() < this.order.amount ? 'error' : this.order.cardDistributionsSum() === this.order.amount ? 'success' : 'error'
  }

  get cardDistributions(): CardDistribution[] {
    return this.cardDistributionsList.length === 1 ? [] : this.cardDistributionsList.slice(0, -1)
  }

  get isWholesaler() {
    return this.userStore.roles.includes('wholesaler')
  }

  public resetCardDistribution(): void {
    this.activeLineCardDistributions = 0
    this.cardDistributionsList = []
  }

  public addCardDistributionFromOrder(cardDistributions: CardDistribution[]): void {
    this.cardDistributionsList = [...cardDistributions]
    this.activeLineCardDistributions = cardDistributions.length
  }

  public addCardDistribution() {
    const sku = this.order.giftCard.skus.sort((a: any, b: any) => a.faceValue - b.faceValue)[0].dup()
    this.cardDistributionsList.push(new CardDistribution({ quantity: 1, amount: 0, sku: sku, reservedQuantity: 0 }))
  }

  private async deleteCardDistribution(cd: CardDistribution, index: number) {
    if (cd.isPersisted) {
      await cd.destroy()

      this.$notify({
        title: this.$t('orderFormDialog.notification.delete.title') as string,
        message: this.$t('orderFormDialog.notification.delete.message') as string,
        type: 'success',
        duration: 2000
      })
    }
    this.cardDistributionsList.splice(index, 1)
    this.$emit('updateCardDistribution', this.cardDistributions)
  }

  private handleSkuSelect(skuId: string, cdIndex: number) {
    const selected = this.order.giftCard.skus.find(e => e.id === skuId)
    if (selected) this.cardDistributionsList[cdIndex].sku = selected.dup()
  }
}
