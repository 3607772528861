import { DirectiveOptions } from 'vue'
import { useUserStore } from '@/stores/user'

export const permission: DirectiveOptions = {
  inserted(el, binding) {
    const userStore = useUserStore()
    const { value } = binding
    const roles = userStore.roles
    if (value && value instanceof Array && value.length > 0) {
      const permissionRoles = value
      const hasPermission = roles.some(role => {
        return permissionRoles.includes(role)
      })
      if (!hasPermission) {
        el.style.display = 'none'
      }
    } else {
      throw new Error('need roles! Like v-permission="[\'admin\',\'editor\']"')
    }
  }
}
