
import { Component, Vue, Prop } from 'vue-property-decorator'

import { GiftCard, Sku } from '@/models/index'

@Component({
  name: 'GiftCardSelect'
})
export default class extends Vue {
  @Prop({ required: true }) private selected!: string
  @Prop({ required: true }) private giftCards!: GiftCard[]
  @Prop({ required: false, default: false }) private loading!: boolean
  @Prop({ required: false, default: false }) private disabled!: boolean
  @Prop({ required: false, default: 'Select a Gift Card' }) private placeholder!: string
  @Prop({ required: false, default: false }) private clearable!: boolean
  @Prop({ required: false, default: false }) private displayStock!: boolean
  @Prop({ required: false, default: false }) private displayMode!: boolean
  @Prop({ required: false, default: false }) private displayDiscount!: boolean
  @Prop({ required: false, default: false }) private multiple!: boolean

  get giftCardsFormatted(): any {
    const tempList: any = {}

    this.giftCards.forEach(e => {
      const firstChar = e.name.charAt(0)
      if (!tempList[firstChar]) tempList[firstChar] = { label: firstChar, options: [e] }
      else tempList[firstChar].options.push(e)
    })

    return [...Object.values(tempList)]
  }

  checkGiftCardStock(skus: Sku[]): boolean {
    const totalStock = skus.reduce((acc: number, current: Sku): number => {
      if (!current.cardStock) return acc
      return acc + Number(current.cardStock.inStockCount)
    }, 0)

    return totalStock > 1
  }
}
