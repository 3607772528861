
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { CardDistribution, CardStock, Gift, GiftCard, Sku } from "@/models/index";

import VueNumeric from 'vue-numeric'

@Component({
  name: 'SelectCustomer',
  components: {
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue'),
    VueNumeric
  }
})
export default class extends Vue {
  @Prop({ required: true }) private gift!: Gift

  private giftCards: GiftCard[] = []
  private giftCardsLoading = false
  private paginationGiftCards = {
    page: 1,
    perPage: 999
  }

  private cardDistributions: CardDistribution[] = []
  private totalAmount = 0

  @Watch('gift.recipient')
  private async getGiftCards() {
    this.giftCardsLoading = true

    const { data } = await GiftCard
        .includes([{ cardStocks: ['sku'] }, 'skus'])
        .select(['name', 'logoSquare'])
        .selectExtra(['apiEnabled'])
        .per(this.paginationGiftCards.perPage)
        .page(this.paginationGiftCards.page)
        .order('name')
        .all()


    this.giftCards.push(...data)
    this.handleGiftCardSelect(this.giftCards[0].id)

    this.giftCardsLoading = false
  }

  private handleGiftCardSelect(giftCardId: string) {
    const selected = this.giftCards.find(giftCard => giftCard.id === giftCardId)

    if (selected) {
      this.$emit('selectGiftCard', selected)
      this.totalAmount = 0
      this.cardDistributions = []
      this.cardDistributionsBySku(this.gift.giftCard.cardStocks)
    }
  }

  private formatCardStock(cardStocks: CardStock[]): CardStock[] {
    return cardStocks.filter(cs => cs.sku && !cs.sku.faceValueFree && !cs.sku.discardedAt).sort((a, b) => a.sku.faceValue - b.sku.faceValue)
  }

  private cardDistributionsBySku(cardStocks: CardStock[]) {
    this.cardDistributions = []
    const cardStocksList = this.formatCardStock(cardStocks)

    for (const cardStock of cardStocksList) {
      this.cardDistributions.push(new CardDistribution({
        quantity: 0,
        amount: cardStock.sku.faceValue,
        sku: { ...cardStock.sku, cardStock: { ...cardStock } }
      }))
    }
  }

  private handleCardQuantity() {
    this.$emit('selectCardDistributions', this.cardDistributions)
    this.totalAmount = this.cardDistributions.reduce((acc: number, current: CardDistribution): number => {
      return acc + (current.amount * current.quantity)
    }, 0)
  }

  private displayCardStock(stock: any): string {
    if ((!this.gift.giftCard.apiEnabled && stock && stock.inStockCount === 0) || !stock) return '<span class="text-warning">Précommande</span>'
    return `<span class="text-success">${this.gift.giftCard.apiEnabled ? 'Illimité' : stock.inStockCount > 1 ? `${stock.inStockCount} cartes` : `${stock.inStockCount} carte`}</span>`
  }
}
