
import { Component, Prop, Vue } from 'vue-property-decorator'

import { Gift } from '@/models/index'

@Component({
  name: 'GiftConfirmation'
})
export default class extends Vue {
  @Prop({ required: true }) private gift!: Gift
}
