
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CardDistribution } from '@/models'

@Component({
  name: 'CardDistributionTooltip'
})
export default class extends Vue {
  @Prop({ required: true }) private cardDistributions!: CardDistribution[]
}
