
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { Wholesaler, Order, GiftCard, CardDistribution, PurchaseInfo, Balance } from '@/models/index'

import { useUserStore } from '@/stores/user'

import { Form } from 'element-ui'

import OrderDiscountCalculator from '@/utils/orderDiscountCalculator'
import OrderFormDetails from './components/OrderFormDetails.vue'
import { Sku } from "@/models";

@Component({
  name: 'OrderFormDialog',
  components: {
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue'),
    OrderSummaryFooter: () => import('@/views/orders/orders-list/components/OrderSummaryFooter.vue'),
    OrderFormDetails
  }
})
export default class extends Vue {
  @Prop({ required: false, default: null }) private order!: Order
  @Prop({ required: false, default: false }) private orderToCreate!: boolean
  @Prop({ required: true }) private visible!: boolean

  @Watch('loading')
  private onLoadingChange(loading: boolean) {
    if (!loading) {
      this.createOrderLoading = false
      this.getBalance()
      ;(this.$refs.orderFormCardDistributions as OrderFormDetails)?.resetCardDistribution()
      !this.order ? this.handleCreate() : this.orderToCreate ? this.handlePreCreate() : this.handleUpdate()
    }
  }

  @Watch('tempData.amount')
  private onOrderAmountChange(newAmount: number) {
    if (this.odc) this.assignOrderDiscount(newAmount)
  }

  @Watch('tempData.balanceBurned')
  private onBalanceBurnedChange(newBalanceBurned: number) {
    this.odc!.balanceBurned = newBalanceBurned
    this.tempData.payableAmount = this.odc!.values.payableAmount
  }

  public userStore: any = useUserStore()

  private balance = 0

  private loading = true

  private giftCards: GiftCard[] = []
  private giftCardsLoading = true
  private paginationGiftCards = {
    page: 1,
    perPage: 999
  }

  private skusLoading = false

  private wholesaler: Wholesaler = new Wholesaler()
  private sellerPurchaseInfo: PurchaseInfo = new PurchaseInfo()
  private wholesalerPurchaseInfo: PurchaseInfo = new PurchaseInfo()
  private purchaseInfoLoading = true
  private wholesalerLoading = true
  private createOrderLoading = false

  private odc: OrderDiscountCalculator | null = null

  private dialogStatus = ''
  private textMap: any = {
    update: this.$t('orderFormDialog.titleUpdate'),
    create: this.$t('orderFormDialog.titleCreate')
  }

  private rules = {
    giftCard: [{ required: true, message: this.$t('orderFormDialog.rules.requiredGiftCard'), trigger: 'change' }],
    amount: [{ required: true, message: this.$t('orderFormDialog.rules.requiredAmount'), trigger: 'blur' }],
    cardDistributions: [{ validator: this.validateCardDistributions, trigger: 'blur' }]
  }

  private tempData = new Order({ amount: 0, giftCard: new GiftCard({ id: '' }), balanceBurned: 0 })

  get dialogTitle() {
    let title = this.textMap[this.dialogStatus]
    if (this.dialogStatus === 'update') title += ` ${this.tempData.number}`
    return title
  }

  get buyerId() {
    return this.userStore.model.manageable.id
  }

  get deferredAmountRemaining() {
    const sellerRemaining = this.sellerPurchaseInfo.deferredAmountAvailable
    const wholesalerRemaining = this.wholesalerPurchaseInfo.deferredAmountAvailable

    if (wholesalerRemaining < 0) return -1
    else if (sellerRemaining < 0) return wholesalerRemaining
    else return sellerRemaining < wholesalerRemaining ? sellerRemaining : wholesalerRemaining
  }

  get isWholesaler(): boolean {
    return this.userStore.roles.includes('wholesaler')
  }

  get isBuyer() {
    return this.userStore.roles.includes('buyer')
  }

  async created(): Promise<void> {
    await this.getGiftCards()
    await this.getWholesaler()

    this.loading = false
  }

  private assignOrderDiscount(amount: number | null = null) {
    if (!amount) amount = this.tempData.amount
    this.odc!.baseAmount = amount

    this.tempData.discount = this.odc!.values.discount
    this.tempData.discountWithoutVat = this.odc!.values.discountWithoutVat
    this.tempData.discountedAmount = this.odc!.values.discountedAmount
    this.tempData.discountedAmountWithoutVat = this.odc!.values.discountedAmountWithoutVat
    this.tempData.chargeableAmount = this.odc!.values.chargeableAmount
    this.tempData.payableAmount = this.odc!.values.payableAmount
  }

  private async getWholesaler() {
    this.wholesalerLoading = true
    const { data } = await Wholesaler.all()

    this.wholesaler = data[0]
    this.wholesalerLoading = false
  }

  private async getGiftCards() {
    this.giftCardsLoading = true

    const { data } = await GiftCard
      .includes(['defaultGiftCardSeller'])
      .per(this.paginationGiftCards.perPage)
      .page(this.paginationGiftCards.page)
      .select(['name', 'logoSquare'])
      .order('name')
      .all()

    this.giftCards.push(...data)
    this.giftCardsLoading = false
  }

  private async getSkus() {
    this.skusLoading = true

    const { data } = await Sku
      .where({
        giftCardId: this.tempData.giftCard.id,
        faceValueFree: false
      })
      .all()

    this.tempData.giftCard.skus = data
    this.skusLoading = false
  }

  private async getPurchaseInfo() {
    this.purchaseInfoLoading = true
    const { data } =
      await PurchaseInfo
        .where({ sellerId: this.tempData.giftCard.defaultGiftCardSeller.sellerId })
        .where({ wholesalerId: this.wholesaler.id })
        .where({ giftCardId: this.tempData.giftCard.id })
        .includes(['buyer', { rate: 'rateCard' }])
        .selectExtra(['deferredAmountAvailable'])
        .all()

    this.sellerPurchaseInfo = data.find(e => e.buyer === undefined) || new PurchaseInfo()
    this.wholesalerPurchaseInfo = data.find(e => e.buyer !== undefined) || new PurchaseInfo()

    this.odc = new OrderDiscountCalculator(this.sellerPurchaseInfo, this.wholesalerPurchaseInfo, this.isBuyer)
    this.assignOrderDiscount()

    this.purchaseInfoLoading = false
  }

  async getBalance() {
    const { data } = await Balance.all()
    this.balance = data[0] ? data[0].amount : 0
  }

  private async handleCreate() {
    this.tempData = new Order({
      amount: 0,
      giftCard: this.giftCards[0].dup(),
      balanceBurned: 0,
      prepareToDownload: !this.isWholesaler
    })

    await this.getPurchaseInfo()
    await this.getSkus()
    this.dialogStatus = 'create'
    this.$nextTick(() => {
      if (this.tempData.cardDistributions.length === 0) (this.$refs.orderFormCardDistributions as OrderFormDetails).addCardDistribution()
      ;(this.$refs.dataForm as Form).clearValidate()
    })
  }

  private async handlePreCreate() {
    this.tempData = new Order({
      amount: 0,
      giftCard: this.order.giftCard.dup(),
      cardDistributions: this.order.cardDistributions,
      balanceBurned: 0,
      prepareToDownload: !this.isWholesaler
    })

    await this.getPurchaseInfo()
    await this.getSkus()
    this.tempData.amount = this.order.amount
    this.dialogStatus = 'create'
    this.$nextTick(() => {
      if (this.tempData.cardDistributions.length > 0) (this.$refs.orderFormCardDistributions as OrderFormDetails).addCardDistributionFromOrder(this.tempData.cardDistributions)
      ;(this.$refs.orderFormCardDistributions as OrderFormDetails).addCardDistribution()
      ;(this.$refs.dataForm as Form).clearValidate()
    })
  }

  private async handleUpdate() {
    this.tempData = this.order.dup()
    await this.getPurchaseInfo()
    this.dialogStatus = 'update'
    this.$nextTick(() => {
      if (this.tempData.cardDistributions.length > 0) (this.$refs.orderFormCardDistributions as OrderFormDetails).addCardDistributionFromOrder(this.tempData.cardDistributions)
      ;(this.$refs.orderFormCardDistributions as OrderFormDetails).addCardDistribution()
      ;(this.$refs.dataForm as Form).clearValidate()
    })
  }

  private async createOrUpdate() {
    (this.$refs.dataForm as Form).validate(async(valid) => {
      this.createOrderLoading = true
      if (valid) {
        const data = this.tempData

        try {
          await data.save({
            with: ['giftCard.id', { cardDistributions: 'sku' }],
            returnScope: Order.includes('invoices')
          })

          if (this.dialogStatus === 'create') {
            this.$notify({
              title: this.$t('orderFormDialog.notification.create.title') as string,
              message: this.$t('orderFormDialog.notification.create.message') as string,
              type: 'success',
              duration: 2000
            })
            this.$emit('created', data)
            this.$emit('close')
          } else {
            this.$notify({
              title: this.$t('orderFormDialog.notification.update.title') as string,
              message: this.$t('orderFormDialog.notification.update.message') as string,
              type: 'success',
              duration: 2000
            })
            this.$emit('updated', data)
            this.$emit('close')
          }
        } catch (err) {
          this.$notify({
            title: this.$t('orderFormDialog.notification.error.title') as string,
            message: this.$t('orderFormDialog.notification.error.message') as string,
            type: 'success',
            duration: 2000
          })
          this.createOrderLoading = false
        }
      }
      this.createOrderLoading = false
    })
  }

  private updateCardDistributions(cardDistributions: CardDistribution[], addNewCD = false) {
    this.tempData.cardDistributions = [...cardDistributions]
    this.setOrderAmountFromCardDistribution()
    if (addNewCD) (this.$refs.orderFormCardDistributions as OrderFormDetails).addCardDistribution()
  }

  private handleGiftCardSelect(giftCardId: string) {
    const selected = this.giftCards.find(giftCard => giftCard.id === giftCardId)

    if (selected) {
      this.tempData.giftCard = selected.dup()
      this.resetDetails()
      this.getPurchaseInfo()
    }
  }

  private async resetDetails() {
    this.tempData.cardDistributions = []
    ;(this.$refs.orderFormCardDistributions as OrderFormDetails).resetCardDistribution()
    await this.getSkus()
    ;(this.$refs.orderFormCardDistributions as OrderFormDetails).addCardDistribution()
  }

  private setOrderAmountFromCardDistribution() {
    if (!this.tempData.paymentStatus || this.tempData.paymentStatus === 'unpaid') {
      this.tempData.amount = this.tempData.cardDistributions
        .reduce((acc: number, current: CardDistribution): number => {
          const amount = current.sku.faceValueFree ? current.amount : current.sku.faceValue
          return acc + (amount * current.quantity)
        }, 0)
    }
  }

  private validateCardDistributions(rule: any, value: any, callback: any) {
    if (this.tempData.paymentStatus === 'paid') {
      const total = value
        .reduce((acc: number, current: CardDistribution): number => {
          const amount = current.sku.faceValueFree ? current.amount : current.sku.faceValue
          return acc + (amount * current.quantity)
        }, 0)

      total === this.tempData.amount ? callback() : callback(new Error(this.$t('orderFormDialog.rules.detailsNotEqual') as string))
    }
    callback()
  }
}
