
import { Component, Vue, Prop } from 'vue-property-decorator'
import { DotationOperation, MultiChoiceGiftCard } from '@/models/index'

@Component({
  name: 'MultiChoiceCardDistributionsForm'
})
export default class extends Vue {
  @Prop({ required: true }) private multiChoiceCardDistributions!: any[]
  @Prop({ required: true }) private operation!: DotationOperation

  private multiChoiceGiftCards: MultiChoiceGiftCard[] = []
  private multiChoiceGiftCardsLoading = false

  private activeLineMultiChoiceCardDistributions = 0
  private editLane: any = null


  get multiChoiceCardDistributionsTotal(): number {
    if (this.multiChoiceCardDistributions.length > 1) {
      return this.multiChoiceCardDistributions.slice(0, -1).reduce((acc: number, cur: any): number => {
        return (cur.quantity * cur.amount) + acc;
      }, 0);
    } else return 0
  }

  created() {
    this.getMultiChoiceGiftCards()
  }

  private async getMultiChoiceGiftCards() {
    this.multiChoiceGiftCardsLoading = true

    const { data } = await MultiChoiceGiftCard
      .page(1)
      .per(999)
      .stats({ total: 'count' })
      .all()

    this.multiChoiceGiftCards.push(...data)
    if (this.multiChoiceCardDistributions.length === 0) this.newMultiChoiceCardDistribution()

    this.multiChoiceGiftCardsLoading = false
  }

  private newMultiChoiceCardDistribution() {
    this.$emit('addMultiChoiceCardDistribution', { quantity: 1, amount: 50, multi_choice_gift_card_id: this.multiChoiceGiftCards[0].id })
  }
}
