
import { Component, Vue } from 'vue-property-decorator'
import settings from '../../settings'
import { useAppStore } from '@/stores/app'

@Component({
  name: 'LangSelect'
})
export default class extends Vue {
  public appStore: any = useAppStore()

  get language() {
    return this.appStore.language
  }

  private handleSetLanguage(lang: string) {
    this.$i18n.locale = lang
    this.$moment.locale(lang)
    this.appStore.setLanguage(lang)
    document.documentElement.lang = lang
    const title = this.$route?.meta?.title ? `${this.$t(`route.${this.$route.meta.title}`)} - ${settings.title}` : `${settings.title}`
    document.title = title
    window.location.reload()
  }
}
