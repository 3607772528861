
import { Component, Prop, Vue } from "vue-property-decorator"

import { Delivery, Order } from '@/models'

@Component({
  name: 'DownloadManagerDialog',
  components: {
    DeliveryFile: () => import('./components/DeliveryFile.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private order!: Order

  public visible = false
  public deliveries: Delivery[] = []

  setVisibility(visible: boolean): void {
    this.visible = visible
  }

  setDeliveries(deliveries: Delivery[]): void {
    this.deliveries = deliveries
  }

  updateDelivery(delivery: any): void {
    const deliveryIndex = this.deliveries.findIndex((d: any) => d.id === delivery.id)

    if (deliveryIndex !== -1) {
      let deliveryToUpdate = this.deliveries[deliveryIndex].dup()
      deliveryToUpdate.batchInProcess = delivery.batch_in_process
      deliveryToUpdate.bid = delivery.bid
      this.deliveries.splice(deliveryIndex, 1, deliveryToUpdate)
    }
  }
}
