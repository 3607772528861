
import { Component, Vue } from 'vue-property-decorator'
import { Gift, Customer, GiftCard, CardDistribution } from '@/models/index'
import { Form as ElForm } from 'element-ui/types/element-ui'
import SelectCustomer from './components/SelectCustomer.vue'

@Component({
  name: 'Gifts',
  components: {
    SelectCustomer,
    SetGiftDetails: () => import('./components/SetGiftDetails.vue'),
    GiftSummary: () => import('./components/GiftSummary.vue'),
    GiftConfirmation: () => import('./components/GiftConfirmation.vue')
  }
})
export default class extends Vue {
  private gift: Gift = new Gift({ giftCard: new GiftCard({ id: '' }) })

  private step = 0
  private loadingCreate = false

  private copyCardDistributions: CardDistribution[] = []

  get canChooseGiftCard(): boolean {
    return !!this.gift?.recipient
  }

  get giftAmount(): number {
    if (!this.gift.cardDistributions) return 0

    return this.gift?.cardDistributions.reduce((acc: number, current: CardDistribution): number => {
      return acc + (current.amount * current.quantity)
    }, 0)
  }

  private setCustomerGift(customer: Customer): void {
    this.gift.recipient = customer
  }

  private setGiftCard(giftCard: GiftCard): void {
    this.gift.giftCard = giftCard
    this.gift.giftCardId = giftCard.id
  }

  private setCardDistributions(cardDistributions: CardDistribution[]): void {
    this.gift.cardDistributions = cardDistributions
    this.copyCardDistributions = cardDistributions
  }

  async submitForm(): Promise<any> {
    (this.$refs.giftForm as ElForm).validate(async(valid) => {
      if (valid) {
        this.loadingCreate = true

        try {
          this.gift.cardDistributions = this.formatCardDistribution()

          await this.gift.save({ with: 'recipient.id' })

          this.step++
          this.loadingCreate = false
        } catch (err) {
          this.loadingCreate = false
        }
      }
    })
  }

  private formatCardDistribution() {
    const tempList = this.gift.cardDistributions
    const tempObj: any = {}

    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].quantity === 0) {
        tempList.splice(i, 1)
        i--
      }
    }

    tempList.forEach((cardDistibution: CardDistribution) => {
      tempObj[(cardDistibution.sku.faceValue * 100)] = cardDistibution.quantity
    })

    return tempObj
  }

  private resetGift(): void {
    (this.$refs.selectCustomer as SelectCustomer).queryValue = new Customer()

    this.step = 0
    this.copyCardDistributions = []
    this.gift = new Gift({ giftCard: new GiftCard({ id: '' }) })
  }
}
