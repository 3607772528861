
import { Component, Prop, Vue } from 'vue-property-decorator'

import JsFileDownloader from 'js-file-downloader'
import { Balance, BalanceExtract, MyBuyersBalanceExtract } from '@/models'

@Component({
  name: 'ExtractPrepaidAccountDialog',
})
export default class extends Vue {
  @Prop({ required: true }) private visible!: boolean
  @Prop({ required: true }) private balance!: Balance | null
  @Prop({ required: false, default: false }) private fromBuyers!: boolean
  @Prop({ required: false, default: 'buyer' }) private buyerName!: string

  private balanceExtracts: MyBuyersBalanceExtract[] | BalanceExtract[] = []
  private loadingBalanceExtracts = true

  // private selectedBalanceExtract: MyBuyersBalanceExtract | BalanceExtract = new BalanceExtract({ id: ''})
  private selectedBalanceExtractId: string | null = null
  private loadingDownload = false

  created(): void {
    this.getBalanceExtracts()
  }

  get selectedBalanceExtract(): any {
    return this.selectedBalanceExtractId ? this.balanceExtracts.find((extract: MyBuyersBalanceExtract | BalanceExtract) => extract.id === this.selectedBalanceExtractId) : null
  }

  async getBalanceExtracts(): Promise<void> {
    const scope = this.fromBuyers ? MyBuyersBalanceExtract : BalanceExtract

    const { data } = await scope
      .where({ balanceId: this.balance!.id })
      .page(1)
      .per(999)
      .order({ createdAt: 'desc' })
      .all()

    this.balanceExtracts = data
    this.selectedBalanceExtractId = data.length > 0 ? data[0].id : null
    this.loadingBalanceExtracts = false
  }

  async download() {
    this.loadingDownload = true
    const date = new Date(this.selectedBalanceExtract.createdAt)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    const stringDate = `${day}_${month}_${year}`

    await new JsFileDownloader({
      url: this.selectedBalanceExtract.file,
      filename: `${this.buyerName}_${stringDate}.csv`,
      contentType: 'text/csv',
      forceDesktopMode: true
    }).catch((err) => {
      this.$notify({
        title: this.$t('prepaidAccount.header.filters.extraction.notification.error.title') as string,
        message: err,
        type: 'error',
        duration: 2000
      });
    });

    this.loadingDownload = false
  }
}
