
import { Component, Vue } from 'vue-property-decorator'
import { Customer } from '@/models/index'

@Component({
  name: 'SelectCustomer'
})
export default class extends Vue {
  queryValue: Customer = new Customer()
  private queryOptions: Customer[] = []
  private queryLoading = false

  queryCustomer(query: string) {
    if (query) {
      this.queryLoading = true
      setTimeout(async() => {
        const { data } = await Customer
            .where({ email: { match: query } })
            .includes(['program'])
            .page(1)
            .per(10)
            .order('email')
            .all()

        this.queryOptions = { ...data }
        this.queryLoading = false
      }, 200)
    } else {
      this.queryOptions = []
    }
  }
}
