
import { Component, Prop, Vue } from 'vue-property-decorator'
import { getToken } from '@/utils/local-storage'

import JsFileDownloader from 'js-file-downloader'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'ExtractInvoicesDialog'
})
export default class extends Vue {
  @Prop({ required: true }) private visible!: boolean

  userStore: any = useUserStore()


  baseUrl: string = process.env.VUE_APP_API_URL || 'http://localhost:80'

  dateRange: any = null
  loadingDownload = false

  get wholesalerName() {
    return this.userStore.model.manageable.name
  }

  async download() {
    this.loadingDownload = true

    const startDate = this.$moment(String(this.dateRange![0])).format('DD-MM-YYYY')
    const endDate = this.$moment(String(this.dateRange![1])).format('DD-MM-YYYY')
    const stringDate = `${startDate}->${endDate}`

    await new JsFileDownloader({
      url: `${this.baseUrl}/market/v1/invoices/export_csv?start_at=${startDate}&end_at=${endDate}`,
      filename: `${this.wholesalerName}_invoices_${stringDate}.csv`,
      contentType: 'text/csv',
      forceDesktopMode: true,
      headers: [
        { name: 'Authorization', value: `Bearer ${getToken()}` }
      ]
    }).catch((err) => {
      this.$notify({
        title: this.$t('extractInvoicesDialog.notification.error.title') as string,
        message: err,
        type: 'error',
        duration: 2000
      })
    })

    this.loadingDownload = false
  }
}
